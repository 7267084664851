import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "config";
import { toast } from "react-toastify";

const ROLE_BASE_URL = `${BASE_URL}api/v2/role`;

const initialState = {
  roles: [],
  loading: true,
  role: {},
};

export const getRoles = createAsyncThunk(
  "role/getRoles",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(`${ROLE_BASE_URL}`, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRole = createAsyncThunk(
  "role/getRole",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(`${ROLE_BASE_URL}/${values}`, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addRoles = createAsyncThunk(
  "role/addRoles",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(`${ROLE_BASE_URL}`, values, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRoles = createAsyncThunk(
  "role/updateRoles",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `${ROLE_BASE_URL}/${values.roleId}`,
        values,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoles = createAsyncThunk(
  "role/deleteRoles",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.delete(
        `${ROLE_BASE_URL}/${values.roleId}`,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const role = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: {
    [getRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.roles = action.payload.roles;
    },
    [getRoles.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [getRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.role = action.payload.role;
    },
    [getRole.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [addRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [addRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.roles = action.payload.roles;
      toast.success(action.payload.message);
    },
    [addRoles.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [updateRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [updateRoles.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message);
    },
    [updateRoles.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [deleteRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteRoles.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message);
    },
    [deleteRoles.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
  },
});

export default role.reducer;

export const {} = role.actions;
