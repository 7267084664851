import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

const Feed = Loadable(lazy(() => import("pages/linkPost/Feed")));
const UserFeed = Loadable(lazy(() => import("pages/linkPost/UserFeed")));

// ==============================|| AUTH ROUTING ||============================== //

const SharedPostRoute = () => {
  return {
    path: "/",
    element: <MinimalLayout />,
    children: [
      {
        path: "post/:title/:slug",
        // path: "shared/:id",
        element: <Feed />,
      },
      {
        // path: ":title/:id",
        path: "user/:name/:id",
        element: <UserFeed />,
      },
    ],
  };
};

export default SharedPostRoute;
