import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "config";
import { toast } from "react-toastify";

const FEED_BASE_URL = `${BASE_URL}api/v2/feed`;

const initialState = {
  feeds: [],
  loading: true,
  feed: {},
  feedCount: 0,
};

export const getFeeds = createAsyncThunk(
  "feed/getFeeds",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      let apiUrl = `${FEED_BASE_URL}?page=${values.page}&limit=${values.limit}`;

      if (values.fromDate) {
        apiUrl += `&from=${values.fromDate}`;
      }

      if (values.toDate) {
        apiUrl += `&to=${values.toDate}`;
      }

      if (values.input) {
        apiUrl += `&searchQuery=${values.input}`;
      }

      const res = await axios.get(apiUrl, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFeed = createAsyncThunk(
  "feed/getFeed",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(`${FEED_BASE_URL}/${values}`, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addFeed = createAsyncThunk(
  "feed/addFeed",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(`${FEED_BASE_URL}`, values, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFeed = createAsyncThunk(
  "feed/updateFeed",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `${FEED_BASE_URL}/${values.feedId}`,
        values,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const feedApproval = createAsyncThunk(
  "feed/feedApproval",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `${FEED_BASE_URL}/approve/${values.id}`,
        { approved: values.approved },
        config
      );
      toast.success(res.data.message);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFeed = createAsyncThunk(
  "feed/deleteFeed",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.delete(
        `${FEED_BASE_URL}/${values.feedId}`,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const feed = createSlice({
  name: "feed",
  initialState,
  reducers: {},
  extraReducers: {
    [getFeeds.pending]: (state, action) => {
      state.loading = true;
    },
    [getFeeds.fulfilled]: (state, action) => {
      state.loading = false;
      state.feeds = action.payload.feeds;
      state.feedCount = action.payload.docCount;
    },
    [getFeeds.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [getFeed.pending]: (state, action) => {
      state.loading = true;
    },
    [getFeed.fulfilled]: (state, action) => {
      state.loading = false;
      state.feed = action.payload.feed;
    },
    [getFeed.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [addFeed.pending]: (state, action) => {
      state.loading = true;
    },
    [addFeed.fulfilled]: (state, action) => {
      state.loading = false;
      state.feeds = action.payload.feeds;
      toast.success(action.payload.message);
    },
    [addFeed.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [updateFeed.pending]: (state, action) => {
      state.loading = true;
    },
    [updateFeed.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message);
    },
    [updateFeed.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [deleteFeed.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteFeed.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message);
    },
    [deleteFeed.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    // [feedApproval.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [feedApproval.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   toast.success(action.payload.message);
    // },
    // [feedApproval.rejected]: (state, action) => {
    //   state.loading = false;
    //   toast.error(action.payload.message);
    // },
  },
});

export default feed.reducer;

export const {} = feed.actions;
