import { ChromeOutlined, UnorderedListOutlined } from "@ant-design/icons";

const icons = {
  ChromeOutlined,
  UnorderedListOutlined,
};

const roleManagement = (children) => {
  const roleManagementModule = {
    getRoles: {
      id: "getRoles",
      title: "Role List",
      type: "item",
      url: "/role/list",
      icon: icons.UnorderedListOutlined,
    },
  };

  const roleManagement = {
    id: "roleManagement",
    title: "Role Management",
    type: "group",
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (roleManagementModule[subModule]) {
          roleManagement.children.push(roleManagementModule[subModule]);
        }
      }
    }
  }

  return roleManagement;
};

export default roleManagement;
