import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "config";
import { toast } from "react-toastify";

const MODULE_BASE_URL = `${BASE_URL}api/v2/module`;

const initialState = {
  modules: [],
  loading: true,
};

export const getModules = createAsyncThunk(
  "module/getModules",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(`${MODULE_BASE_URL}`, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const module = createSlice({
  name: "module",
  initialState,
  reducers: {},
  extraReducers: {
    [getModules.pending]: (state, action) => {
      state.loading = true;
    },
    [getModules.fulfilled]: (state, action) => {
      state.loading = false;
      state.modules = action.payload.modules;
    },
    [getModules.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
  },
});

export default module.reducer;

export const {} = module.actions;
