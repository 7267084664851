import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

const Login = Loadable(lazy(() => import("pages/web/Auth/Login")));
// const UserFeed = Loadable(lazy(() => import("pages/linkPost/UserFeed")));

// ==============================|| AUTH ROUTING ||============================== //

const WebAuthRoutes = () => {
  return {
    path: "/",
    element: <MinimalLayout />,
    children: [
      {
        path: "login",
        // path: "shared/:id",
        element: <Login />,
      },
      //   {
      //     // path: ":title/:id",
      //     path: "user/:name/:id",
      //     element: <UserFeed />,
      //   },
    ],
  };
};

export default WebAuthRoutes;
