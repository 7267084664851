const permissions = (user) => {
  const userPermissions = [];
  if (user) {
    for (const objKey in user.permissions) {
      if (objKey === "permission") {
        for (const _userPermissions in user.permissions[objKey]) {
          if (_userPermissions) {
            for (const subModule in user.permissions[objKey][
              _userPermissions
            ]) {
              if (
                subModule &&
                user.permissions[objKey][_userPermissions][subModule]
              ) {
                userPermissions.push(`${_userPermissions}-${subModule}`);
              }
            }
          }
        }
      }
    }
  }
  return userPermissions;
};

export default permissions;
