import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { findMe } from "store/reducers/user";
import ErrorRoutes from "./ErrorRoutes";

// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import SharedPostRoute from "./SharedPostRoute";
import WebAuthRoutes from "./WebAuthRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { token } = useSelector((state) => state.user);
  let isLoggedIn = false;
  if (token) {
    isLoggedIn = true;
  }

  return useRoutes([
    MainRoutes(isLoggedIn),
    LoginRoutes(isLoggedIn),
    ErrorRoutes,
    SharedPostRoute(),
  ]);
}
