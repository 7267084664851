import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "config";
import { toast } from "react-toastify";

const LOCATION_BASE_URL = `${BASE_URL}api/v2/location`;

const initialState = {
  locations: [],
  loading: true,
  location: {},
  locationCount: 0,
};

export const getLocations = createAsyncThunk(
  "location/getLocations",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      let apiUrl = `${LOCATION_BASE_URL}`;

      const res = await axios.get(apiUrl, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLocation = createAsyncThunk(
  "location/getLocation",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      let apiUrl = `${LOCATION_BASE_URL}/${values.state}`;

      const res = await axios.patch(apiUrl, values, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const location = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: {
    [getLocations.pending]: (state, action) => {
      state.loading = true;
    },
    [getLocations.fulfilled]: (state, action) => {
      state.loading = false;
      state.locations = action.payload.locations;
      state.locationCount = action.payload.docCount;
    },
    [getLocations.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [updateLocation.pending]: (state, action) => {
      state.loading = true;
    },
    [updateLocation.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message);
    },
    [updateLocation.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
  },
});

export default location.reducer;

export const {} = location.actions;
