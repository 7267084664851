// types
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "config";
import { toast } from "react-toastify";

const USER_BASE_URL = `${BASE_URL}api/v2/user`;

export const addSubAdmin = createAsyncThunk(
  "user/addSubAdmin",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { permissions } = values;

      const convertedPermissions = JSON.stringify(permissions);

      values.permissions = convertedPermissions;

      const res = await axios.post(
        `${USER_BASE_URL}/add/sub-admin`,
        values,
        config
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUser = createAsyncThunk(
  "user/addUser",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(`${USER_BASE_URL}/add/user`, values, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signIn = createAsyncThunk(
  "user/signIn",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${USER_BASE_URL}/login`, values);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${USER_BASE_URL}/forgotPassword`, values);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${USER_BASE_URL}/resetPassword`, values);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const findMe = createAsyncThunk(
  "user/findMe",
  async (values, { rejectWithValue, getState, dispatch }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(`${USER_BASE_URL}/findMe`, values, config);

      return res.data;
    } catch (error) {
      dispatch(logout());
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      let url = `${USER_BASE_URL}/getUsers/${values.roleId}?page=${values.page}&limit=${values.limit}`;

      if (values.fromDate) {
        url += `&from=${values.fromDate}`;
      }

      if (values.toDate) {
        url += `&to=${values.toDate}`;
      }

      if (values.input) {
        url += `&searchQuery=${values.input}`;
      }
      const res = await axios.get(url, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.delete(
        `${USER_BASE_URL}/delete/user/${values}`,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubAdmin = createAsyncThunk(
  "user/getSubAdmin",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `${USER_BASE_URL}/getUsers?page=${values.page}&limit=${values.limit}`,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMe = createAsyncThunk(
  "user/updateMe",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `${USER_BASE_URL}/updateMe`,
        values,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { permissions } = values;

      if (permissions) {
        const convertedPermissions = JSON.stringify(permissions);
        values.permissions = convertedPermissions;
      }

      const res = await axios.patch(
        `${USER_BASE_URL}/updateUser/${values.userId}`,
        values,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `${USER_BASE_URL}/changePassword`,
        values,
        config
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (values, { rejectWithValue }) => {
    try {
      localStorage.removeItem("token");
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// initial state
const initialState = {
  loading: true,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
  error: null,
  user: {},
  users: [],
  message: "",
  findUser: {},
  usersCount: 0,
  userCreateLogin: true,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [addSubAdmin.pending]: (state, action) => {
      state.loading = true;
      state.userCreateLogin = true;
    },
    [addSubAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.userCreateLogin = false;
      toast.success(action.payload.message);
    },
    [addSubAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.userCreateLogin = false;
      toast.error(action.payload.message);
    },
    [addUser.pending]: (state, action) => {
      state.loading = true;
    },
    [addUser.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.loading = false;
    },
    [addUser.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [signIn.pending]: (state, action) => {
      state.loading = true;
    },
    [signIn.fulfilled]: (state, action) => {
      state.loading = false;
      // state.message = action.payload.message;
      state.token = action.payload.token;
      state.user = action.payload.user;
      // toast.error(action.payload.message, { autoClose: 3000 });
      localStorage.setItem("token", JSON.stringify(state.token));
    },
    [signIn.rejected]: (state, action) => {
      state.loading = false;
      // state.error = action.payload.message;
      toast.error(action.payload.message, { autoClose: 3000 });
    },
    [forgotPassword.pending]: (state, action) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      toast.success(action.payload.message);
    },
    [forgotPassword.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [resetPassword.pending]: (state, action) => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      toast.success(action.payload.message);
    },
    [resetPassword.rejected]: (state, action) => {
      state.loading = false;

      toast.error(action.payload.message);
    },
    [logout.fulfilled]: (state, action) => {
      state.token = null;
    },
    [findMe.pending]: (state, action) => {
      state.loading = true;
    },
    [findMe.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.loading = false;
    },
    [findMe.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [updateMe.pending]: (state, action) => {
      state.loading = true;
    },
    [updateMe.fulfilled]: (state, action) => {
      state.loading = false;
      // state.user = action.payload.user;
      toast.success(action.payload.message, { autoClose: 3000 });
      // state.message = action.payload.message;
    },
    [updateMe.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message, { autoClose: 3000 });
      // state.error = action.payload.message;
    },
    [changePassword.pending]: (state, action) => {
      state.loading = true;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message);
      state.message = action.payload.message;
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload.users;
      state.usersCount = action.payload.docCount;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
    [updateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.findUser = {};
      toast.success(action.payload.message, { autoClose: 3000 });
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message, { autoClose: 3000 });
    },
    [deleteUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success(action.payload.message);
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.payload.message);
    },
  },
});

export default user.reducer;

export const {} = user.actions;
