import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Avatar,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, updateMe } from "store/reducers/user";
import MainCard from "components/MainCard";
import avatar1 from "assets/images/users/avatar-1.png";
import { strengthColor, strengthIndicator } from "utils/password-strength";
import { Box, FormControl, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordChange = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    passwordChange("");
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Grid container>
        <MainCard
          title="Profile"
          style={{ margin: "0 auto" }}
          sx={{ width: "30%" }}
        >
          <Stack direction="row" spacing={10} alignItems="center">
            <Grid>
              <Stack spacing={2}>
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                    currentPassword: "",
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    currentPassword: Yup.string()
                      .max(255)
                      .required("Current Password is required"),
                    password: Yup.string()
                      .max(255)
                      .required("Password is required"),
                    confirmPassword: Yup.string()
                      .max(255)
                      .required("Confirm Password is required")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Password does not match"
                      ),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      setStatus({ success: false });
                      setSubmitting(false);
                      dispatch(changePassword(values));
                      navigate("../", { replace: true });
                    } catch (err) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="current-password">
                              Current Password
                            </InputLabel>
                            <OutlinedInput
                              fullWidth
                              error={Boolean(
                                touched.currentPassword &&
                                  errors.currentPassword
                              )}
                              id="current-password"
                              type={showCurrentPassword ? "text" : "password"}
                              value={values.currentPassword}
                              name="currentPassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowCurrentPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                  >
                                    {showCurrentPassword ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder="******"
                              inputProps={{}}
                            />
                            {touched.currentPassword && errors.currentPassword && (
                              <FormHelperText
                                error
                                id="helper-text-current-password"
                              >
                                {errors.currentPassword}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="password-signup">
                              Password
                            </InputLabel>
                            <OutlinedInput
                              fullWidth
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              id="password-signup"
                              type={showPassword ? "text" : "password"}
                              value={values.password}
                              name="password"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                passwordChange(e.target.value);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                  >
                                    {showPassword ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder="******"
                              inputProps={{}}
                            />
                            {touched.password && errors.password && (
                              <FormHelperText
                                error
                                id="helper-text-password-signup"
                              >
                                {errors.password}
                              </FormHelperText>
                            )}
                          </Stack>
                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Box
                                  sx={{
                                    bgcolor: level?.color,
                                    width: 85,
                                    height: 8,
                                    borderRadius: "7px",
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="subtitle1"
                                  fontSize="0.75rem"
                                >
                                  {level?.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="confirm-password">
                              Confirm Password
                            </InputLabel>
                            <OutlinedInput
                              fullWidth
                              error={Boolean(
                                touched.confirmPassword &&
                                  errors.confirmPassword
                              )}
                              id="confirm-password"
                              type={showPassword ? "text" : "password"}
                              value={values.confirmPassword}
                              name="confirmPassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="******"
                              inputProps={{}}
                            />
                            {touched.confirmPassword && errors.confirmPassword && (
                              <FormHelperText
                                error
                                id="helper-text--confirm-password"
                              >
                                {errors.confirmPassword}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        {errors.submit && (
                          <Grid item xs={12}>
                            <FormHelperText error>
                              {errors.submit}
                            </FormHelperText>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <AnimateButton>
                            <Button
                              disableElevation
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Change Password
                            </Button>
                          </AnimateButton>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Stack>
            </Grid>
          </Stack>
        </MainCard>
      </Grid>
    </>
  );
};

export default ChangePassword;
