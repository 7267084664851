import {
  UserAddOutlined,
  UnorderedListOutlined,
  DiffOutlined,
} from "@ant-design/icons";

const icons = {
  UserAddOutlined,
  DiffOutlined,
  UnorderedListOutlined,
};
const locationManagement = (children) => {
  const locationManagementModule = {
    addFeed: {
      id: "addState",
      title: "Add Location",
      type: "item",
      url: "/add/state",
      icon: icons.DiffOutlined,
      breadcrumbs: false,
    },
    addCity: {
      id: "addCity",
      title: "Add City",
      type: "item",
      url: "/add/city",
      icon: icons.DiffOutlined,
      breadcrumbs: false,
    },
    getLocations: {
      id: "getLocations",
      title: "Locations",
      type: "item",
      url: "/locations/list",
      icon: icons.UnorderedListOutlined,
    },
  };

  const locationManagement = {
    id: "locationManagement",
    title: "Location Management",
    type: "group",
    breadcrumbs: false,
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (locationManagementModule[subModule]) {
          locationManagement.children.push(locationManagementModule[subModule]);
        }
      }
    }
  }

  return locationManagement;
};

export default locationManagement;
