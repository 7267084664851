// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: "/",
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "en",
  miniDrawer: false,
  container: true,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
};

export default config;
export const drawerWidth = 260;
export const BASE_URL = "https://ootky.devtechnosys.tech:17347/";
// export const BASE_URL = "http://localhost:5000/";
export const SUPER_ADMIN_ROLE_ID = "63476a25bc211fd44b335de5";
export const SUB_ADMIN_ROLE_ID = "63476a7cbc211fd44b335de6";
export const USER_ROLE_ID = "6348051e8fbc69e5fb16cfda";

export const ADD_SUB_ADMIN = "addSubAdmin";
export const GET_SUB_ADMINS = "getSubAdmins";
export const GET_SUB_ADMIN = "getSubAdmin";
export const EDIT_SUB_ADMIN = "editSubAdmin";
export const DELETE_SUB_ADMIN = "deleteSubAdmin";

export const ADD_USER = "addUser";
export const GET_USERS = "getUsers";
export const GET_USER = "getUser";
export const EDIT_USER = "editUser";
export const DELETE_USER = "deleteUser";

export const ADD_FEED = "addFeed";
export const GET_FEEDS = "getFeeds";
export const GET_FEED = "getFeed";
export const EDIT_FEED = "editFeed";
export const DELETE_FEED = "deleteFeed";

export const CMS = "CMS";
export const GET_CMS = "getCMS";
export const EDIT_CMS = "editCMS";
export const DELETE_CMS = "deleteCMS";

export const DASHBOARD = "dashboard";

export const ADD_ROLE = "addRole";
export const GET_ROLES = "getRoles";
export const GET_ROLE = "getRole";
export const EDIT_ROLE = "editRole";
export const DELETE_ROLE = "deleteRole";

export const ADD_LOCATION = "addLocation";
export const ADD_CITY = "addCity";
export const GET_LOCATIONS = "getLocations";
export const GET_LOCATION = "getLocation";
export const EDIT_LOCATION = "editLocation";
export const DELETE_LOCATION = "deleteLocation";
export const DELETE_CITY = "deleteCity";

export const GET_FEEDBACKS = "getFeedbacks";
export const GET_FEEDBACK = "getFeedback";
export const DELETE_FEEDBACK = "deleteRole";

export const GET_QUERIES = "getQueries";
export const GET_QUERY = "getQuery";
export const DELETE_QUERY = "deleteQuery";

export const GET_REQUESTS = "getRequests";
export const GET_USER_REQUESTS = "getUserRequest";
export const ACTION_REQUEST = "actionRequest";

export const SUB_ADMIN_MANAGEMENT = "subAdminManagement";
export const USER_MANAGEMENT = "userManagement";
export const ROLE_MANAGEMENT = "roleManagement";
export const FEED_MANAGEMENT = "feedManagement";
export const CMS_MANAGEMENT = "cmsManagement";
export const LOCATION_MANAGEMENT = "locationManagement";
export const FEEDBACK_MANAGEMENT = "feedbackManagement";
export const QUERY_MANAGEMENT = "queryManagement";
export const REQUEST_MANAGEMENT = "requestManagement";
