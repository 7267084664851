import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import { Navigate } from "react-router-dom";
import NotFound from "pages/Error/404";

const ErrorRoutes = {
  path: "*",
  element: <MinimalLayout />,
  children: [
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default ErrorRoutes;
