import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { Navigate } from "react-router-dom";
import ChangePassword from "pages/authentication/auth-forms/ChangePassword";
import RequirePermission from "./RequirePermission";
import {
  SUB_ADMIN_MANAGEMENT,
  ADD_SUB_ADMIN,
  EDIT_SUB_ADMIN,
  GET_SUB_ADMINS,
  GET_SUB_ADMIN,
  USER_MANAGEMENT,
  ADD_USER,
  GET_USERS,
  GET_USER,
  EDIT_USER,
  ROLE_MANAGEMENT,
  GET_ROLES,
  EDIT_ROLE,
  FEED_MANAGEMENT,
  ADD_FEED,
  GET_FEEDS,
  GET_FEED,
  EDIT_FEED,
  CMS_MANAGEMENT,
  CMS,
  EDIT_CMS,
  LOCATION_MANAGEMENT,
  ADD_LOCATION,
  ADD_CITY,
  GET_LOCATIONS,
  EDIT_LOCATION,
  QUERY_MANAGEMENT,
  GET_QUERIES,
  FEEDBACK_MANAGEMENT,
  GET_FEEDBACKS,
} from "config";
import { REQUEST_MANAGEMENT } from "config";
import { GET_REQUESTS } from "config";
// import ImageDetails from "components/ImageDetails";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - SubAdminPages
const AddSubAdmin = Loadable(lazy(() => import("pages/sub-admin/AddUser")));
const SubAdminDetails = Loadable(
  lazy(() => import("pages/sub-admin/SubAdminDetails"))
);
const SubAdmin = Loadable(lazy(() => import("pages/sub-admin/SubAdmin")));

// render - ProfilePages
const Profile = Loadable(lazy(() => import("pages/profile/Profile")));
const EditProfile = Loadable(lazy(() => import("pages/profile/EditProfile")));

// render - RolePages
const Role = Loadable(lazy(() => import("pages/role/Role")));
const AddRole = Loadable(lazy(() => import("pages/role/AddRole")));

// render - UserPages
const AddUser = Loadable(lazy(() => import("pages/user/AddUser")));
const UserDetails = Loadable(lazy(() => import("pages/user/UserDetails")));
const Users = Loadable(lazy(() => import("pages/user/Users")));

// render - FeedsPages
const Feeds = Loadable(lazy(() => import("pages/feed/Feeds")));
const AddFeed = Loadable(lazy(() => import("pages/feed/AddFeed")));
const FeedDetails = Loadable(lazy(() => import("pages/feed/FeedDetails")));

// render - CMSPages
const CMSPage = Loadable(lazy(() => import("pages/CMS/CMS")));
const UpdateCMS = Loadable(lazy(() => import("pages/CMS/UpdateCMS")));

// render - locationPages
const AddState = Loadable(lazy(() => import("pages/location/AddState")));
const AddCity = Loadable(lazy(() => import("pages/location/AddCity")));
const Locations = Loadable(lazy(() => import("pages/location/Locations")));
const EditLocation = Loadable(
  lazy(() => import("pages/location/EditLocation"))
);

// render - Queries
const Queries = Loadable(lazy(() => import("pages/query/Queries")));

// render - Feedbacks
const Feedback = Loadable(lazy(() => import("pages/feedback/Feedback")));

// render - Requests
const Request = Loadable(lazy(() => import("pages/request/Requests")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn) => {
  return {
    path: "/",
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: "",
        element: <DashboardDefault />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "edit/profile",
        element: <EditProfile />,
      },
      {
        path: "changePassword",
        element: <ChangePassword />,
      },
      //SubAdmin Routes
      {
        path: "/",
        element: (
          <RequirePermission id={`${SUB_ADMIN_MANAGEMENT}-${ADD_SUB_ADMIN}`} />
        ),
        children: [
          {
            path: "sub-admin/add",
            element: <AddSubAdmin mode="add" />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${SUB_ADMIN_MANAGEMENT}-${EDIT_SUB_ADMIN}`} />
        ),
        children: [
          {
            path: "sub-admin/user/:id",
            element: <AddSubAdmin mode="edit" />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${SUB_ADMIN_MANAGEMENT}-${GET_SUB_ADMINS}`} />
        ),
        children: [
          {
            path: "sub-admin/list",
            element: <SubAdmin />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${SUB_ADMIN_MANAGEMENT}-${GET_SUB_ADMIN}`} />
        ),
        children: [
          {
            path: "sub-admin/:id",
            element: <SubAdminDetails />,
          },
        ],
      },
      // User Management Routes
      {
        path: "/",
        element: <RequirePermission id={`${USER_MANAGEMENT}-${ADD_USER}`} />,
        children: [
          {
            path: "add/user",
            element: <AddUser mode="add" />,
          },
        ],
      },

      {
        path: "/",
        element: <RequirePermission id={`${USER_MANAGEMENT}-${GET_USERS}`} />,
        children: [
          {
            path: "user/list",
            element: <Users />,
          },
        ],
      },

      {
        path: "/",
        element: <RequirePermission id={`${USER_MANAGEMENT}-${GET_USER}`} />,
        children: [
          {
            path: "user/:id",
            element: <UserDetails />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${USER_MANAGEMENT}-${EDIT_USER}`} />,
        children: [
          {
            path: "edit/user/:id",
            element: <AddUser mode="edit" />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${ROLE_MANAGEMENT}-${GET_ROLES}`} />,
        children: [
          {
            path: "role/list",
            element: <Role />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${ROLE_MANAGEMENT}-${EDIT_ROLE}`} />,
        children: [
          {
            path: "edit/role/:id",
            element: <AddRole mode="edit" />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${FEED_MANAGEMENT}-${ADD_FEED}`} />,
        children: [
          {
            path: "add/feed",
            element: <AddFeed mode="add" />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${FEED_MANAGEMENT}-${GET_FEEDS}`} />,
        children: [
          {
            path: "feed/list",
            element: <Feeds />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${FEED_MANAGEMENT}-${GET_FEED}`} />,
        children: [
          {
            path: "feed/:id",
            element: <FeedDetails />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${FEED_MANAGEMENT}-${EDIT_FEED}`} />,
        children: [
          {
            path: "edit/feed/:id",
            element: <AddFeed mode="edit" />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${CMS_MANAGEMENT}-${CMS}`} />,
        children: [
          {
            path: "cms/list",
            element: <CMSPage />,
          },
        ],
      },
      {
        path: "/",
        element: <RequirePermission id={`${CMS_MANAGEMENT}-${EDIT_CMS}`} />,
        children: [
          {
            path: "edit/cms/:id",
            element: <UpdateCMS />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${LOCATION_MANAGEMENT}-${ADD_LOCATION}`} />
        ),
        children: [
          {
            path: "add/state",
            element: <AddState mode="add" />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${LOCATION_MANAGEMENT}-${ADD_CITY}`} />
        ),
        children: [
          {
            path: "add/city",
            element: <AddCity />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${LOCATION_MANAGEMENT}-${GET_LOCATIONS}`} />
        ),
        children: [
          {
            path: "locations/list",
            element: <Locations />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${LOCATION_MANAGEMENT}-${EDIT_LOCATION}`} />
        ),
        children: [
          {
            path: "location/edit/:id",
            element: <EditLocation />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${QUERY_MANAGEMENT}-${GET_QUERIES}`} />
        ),
        children: [
          {
            path: "query/list",
            element: <Queries />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${FEEDBACK_MANAGEMENT}-${GET_FEEDBACKS}`} />
        ),
        children: [
          {
            path: "feedback/list",
            element: <Feedback />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <RequirePermission id={`${REQUEST_MANAGEMENT}-${GET_REQUESTS}`} />
        ),
        children: [
          {
            path: "request/list",
            element: <Request />,
          },
        ],
      },
    ],
  };
};

export default MainRoutes;
