import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "config";
import { toast } from "react-toastify";

const COMMENT_BASE_URL = `${BASE_URL}api/v2/app/comment`;

const initialState = {
  comments: [],
  loading: true,
};

export const addComment = createAsyncThunk(
  "comment/addComment",
  async (values, { rejectWithValue, getState }) => {
    try {
      const { user } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.post(`${COMMENT_BASE_URL}`, values, config);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  }
);
const comment = createSlice({
  name: "comment",
  initialState,
  reducers: {
    addCommentToClient(state, action) {
      state.comments = [state.comments, ...action.payload];
    },
  },
  extraReducers: {
    // [addComment.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [addComment.fulfilled]: (state, action) => {
    //   state.loading = false;
    // },
    // [addComment.rejected]: (state, action) => {
    //   state.loading = false;
    //   toast.error(action.payload.message);
    // },
  },
});

export default comment.reducer;

export const { addCommentToClient } = comment.actions;
