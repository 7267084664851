// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import user from "./user";
import module from "./module";
import role from "./role";
import feed from "./feed";
import location from "./location";
import socket from "./socket";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  user,
  module,
  role,
  feed,
  location,
  socket,
});

export default reducers;
