// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import io from "socket.io-client";

// import { AddComment } from "utils/commonApi";
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSocket } from "store/reducers/socket";
import { BASE_URL } from "config";
import SocketClient from "SocketClient";
// import { useEffect } from "react";
// import { findMe } from "store/reducers/user";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(findMe());
  // }, [dispatch]);

  // const socket = useSelector((state) => state.socket);

  // useEffect(() => {
  //   socket.on("createCommentToClient", (newPost) => {
  //     AddComment(newPost);
  //   });

  //   return () => socket.off("createCommentToClient");
  // }, [socket]);

  useEffect(() => {
    const socket = io(BASE_URL);
    dispatch(setSocket(socket));
    return () => {
      socket.close();
    };
  }, [dispatch]);

  return (
    <ThemeCustomization>
      <ToastContainer />
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
