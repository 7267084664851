import { UsergroupAddOutlined, UnorderedListOutlined } from "@ant-design/icons";

const icons = {
  UsergroupAddOutlined,
  UnorderedListOutlined,
};
const subAdminManagement = (children) => {
  const subAdminManagementModule = {
    addSubAdmin: {
      id: "addSubAdmin",
      title: "Add Sub-Admin",
      type: "item",
      url: "/sub-admin/add",
      icon: icons.UsergroupAddOutlined,
    },
    getSubAdmins: {
      id: "getSubAdmins",
      title: "Sub-Admin List",
      type: "item",
      url: "/sub-admin/list",
      icon: icons.UnorderedListOutlined,
    },
  };

  const subAdminManagement = {
    id: "subAdminManagement",
    title: "Sub-Admin Management",
    type: "group",
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (subAdminManagementModule[subModule]) {
          subAdminManagement.children.push(subAdminManagementModule[subModule]);
        }
      }
    }
  }

  return subAdminManagement;
};

export default subAdminManagement;
