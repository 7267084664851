import {
  UserAddOutlined,
  UnorderedListOutlined,
  DiffOutlined,
} from "@ant-design/icons";

const icons = {
  UserAddOutlined,
  DiffOutlined,
  UnorderedListOutlined,
};
const feedManagement = (children) => {
  const feedManagementModule = {
    addFeed: {
      id: "addFeed",
      title: "Add Feed",
      type: "item",
      url: "/add/feed",
      icon: icons.DiffOutlined,
    },
    getFeeds: {
      id: "getFeeds",
      title: "Feed List",
      type: "item",
      url: "/feed/list",
      icon: icons.UnorderedListOutlined,
    },
  };

  const feedManagement = {
    id: "feedManagement",
    title: "Feed Management",
    type: "group",
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (feedManagementModule[subModule]) {
          feedManagement.children.push(feedManagementModule[subModule]);
        }
      }
    }
  }

  return feedManagement;
};

export default feedManagement;
