import { ChromeOutlined, UnorderedListOutlined } from "@ant-design/icons";

const icons = {
  ChromeOutlined,
  UnorderedListOutlined,
};

const requestManagement = (children) => {
  const requestManagementModule = {
    getRoles: {
      id: "getRequests",
      title: "Request List",
      type: "item",
      url: "/request/list",
      icon: icons.UnorderedListOutlined,
    },
  };

  const requestManagement = {
    id: "requestManagement",
    title: "Request Management",
    type: "group",
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (requestManagementModule[subModule]) {
          requestManagement.children.push(requestManagementModule[subModule]);
        }
      }
    }
  }

  return requestManagement;
};

export default requestManagement;
