// assets
import { DashboardOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

// icons
const icons = {
  DashboardOutlined,
};

const dashboard = (children) => {
  const dashboardManagementModule = {
    dashboard: {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
  };

  const dashboardManagement = {
    id: "group-dashboard",
    title: "Navigation",
    type: "group",
    children: [dashboardManagementModule[children]],
  };

  // for (const module of children) {
  //   for (const subModule in module) {
  //     if (module[subModule]) {
  //       if (dashboardManagementModule[subModule]) {
  //         dashboardManagement.children.push(
  //           dashboardManagementModule[subModule]
  //         );
  //       }
  //     }
  //   }
  // }

  return dashboardManagement;
};

export default dashboard;
