import { UserAddOutlined, UnorderedListOutlined } from "@ant-design/icons";

const icons = {
  UserAddOutlined,
  UnorderedListOutlined,
};
const userManagement = (children) => {
  const userManagementModule = {
    addUser: {
      id: "addUser",
      title: "Add User",
      type: "item",
      url: "/add/user",
      icon: icons.UserAddOutlined,
      breadcrumbs: false,
    },
    getUsers: {
      id: "getUsers",
      title: "User List",
      type: "item",
      url: "/user/list",
      icon: icons.UnorderedListOutlined,
      breadcrumbs: false,
    },
  };

  const userManagement = {
    id: "userManagement",
    title: "User Management",
    type: "group",
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (userManagementModule[subModule]) {
          userManagement.children.push(userManagementModule[subModule]);
        }
      }
    }
  }

  return userManagement;
};

export default userManagement;
