import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import { Navigate } from "react-router-dom";
import ChangePassword from "pages/authentication/auth-forms/ChangePassword";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));

const AuthForgotPassword = Loadable(
  lazy(() => import("pages/authentication/ForgotPassword"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("pages/authentication/ResetPassword"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = (isLoggedIn) => {
  return {
    path: "/",
    element: isLoggedIn ? <Navigate to="/" /> : <MinimalLayout />,
    children: [
      {
        path: "login",
        element: <AuthLogin />,
      },
      {
        path: "forgotPassword",
        element: <AuthForgotPassword />,
      },
      {
        path: "resetPassword/:token",
        element: <AuthResetPassword />,
      },
    ],
  };
};

export default LoginRoutes;
