import Loader from "components/Loader";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import permissions from "utils/userPermissions";

const RequirePermission = ({ id }) => {
  let userPermissions = [];
  const { user, loading } = useSelector((state) => state.user);

  if (user) {
    userPermissions = permissions(user);
    // for (const objKey in user.permissions) {
    //   if (objKey === "permission") {
    //     for (const _userPermissions in user.permissions[objKey]) {
    //       if (_userPermissions) {
    //         for (const subModule in user.permissions[objKey][
    //           _userPermissions
    //         ]) {
    //           if (
    //             subModule &&
    //             user.permissions[objKey][_userPermissions][subModule]
    //           ) {
    //             userPermissions.push(`${_userPermissions}-${subModule}`);
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  } else {
    return <Navigate to="/admin" />;
  }
  if (userPermissions.indexOf(id) !== -1) {
    return <Outlet />;
  } else if (!loading && userPermissions.indexOf(id) === -1) {
    return <Navigate to="/admin" />;
  }
};

export default RequirePermission;
