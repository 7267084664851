import {
  UserAddOutlined,
  UnorderedListOutlined,
  DiffOutlined,
} from "@ant-design/icons";

const icons = {
  UserAddOutlined,
  DiffOutlined,
  UnorderedListOutlined,
};
const queryManagement = (children) => {
  const queryManagementModule = {
    getQueries: {
      id: "getQueries",
      title: "Queries",
      type: "item",
      url: "/query/list",
      icon: icons.UnorderedListOutlined,
    },
  };

  const queryManagement = {
    id: "queryManagement",
    title: "Query Management",
    type: "group",
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (queryManagementModule[subModule]) {
          queryManagement.children.push(queryManagementModule[subModule]);
        }
      }
    }
  }

  return queryManagement;
};

export default queryManagement;
