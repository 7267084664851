import { UserAddOutlined, UnorderedListOutlined } from "@ant-design/icons";

const icons = {
  UserAddOutlined,
  UnorderedListOutlined,
};
const cmsManagement = (children) => {
  const cmsManagementModule = {
    // addcms: {
    //   id: "addcms",
    //   title: "Add cms",
    //   type: "item",
    //   url: "/add/cms",
    //   icon: icons.cmsAddOutlined,
    // },
    CMS: {
      id: "CMS",
      title: "CMS List",
      type: "item",
      url: "/cms/list",
      icon: icons.UnorderedListOutlined,
    },
  };

  const cmsManagement = {
    id: "cmsManagement",
    title: "CMS Management",
    type: "group",
    children: [],
  };

  for (const module of children) {
    for (const subModule in module) {
      if (module[subModule]) {
        if (cmsManagementModule[subModule]) {
          cmsManagement.children.push(cmsManagementModule[subModule]);
        }
      }
    }
  }

  return cmsManagement;
};

export default cmsManagement;
