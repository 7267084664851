// project import
import dashboard from "./dashboard";
import userManagement from "./userManagement";
import subAdminManagement from "./subAdminManagement";
import roleManagement from "./roleManagement";
import cmsManagement from "./CMSManagement";
import feedManagement from "./feedManagement";
import locationManagement from "./locationManagement";
import queryManagement from "./queryManagement";
import feedbackManagement from "./feedbackManagement";
import requestManagement from "./requestManagement";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (role, permission) => {
  const menuItem = [dashboard("dashboard")];
  const modules = [];
  const subModule = [];

  if (permission) {
    for (const objKey in permission) {
      if (objKey === "permission") {
        for (const userPermissions in permission[objKey]) {
          modules.push(userPermissions);
          subModule.push(permission[objKey][userPermissions]);
        }
      }
    }
  }

  for (const module of modules) {
    if (module === "userManagement") {
      const _module = userManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }

    if (module === "subAdminManagement") {
      const _module = subAdminManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }

    if (module === "roleManagement") {
      const _module = roleManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }
    // if (module === "group-dashboard") {
    //   menuItem.unshift(dashboard(subModule));
    // }
    if (module === "cmsManagement") {
      const _module = cmsManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }
    if (module === "feedManagement") {
      const _module = feedManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }

    if (module === "locationManagement") {
      const _module = locationManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }

    if (module === "queryManagement") {
      const _module = queryManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }

    if (module === "feedbackManagement") {
      const _module = feedbackManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }

    if (module === "requestManagement") {
      const _module = requestManagement(subModule);
      if (_module && _module.children && _module.children.length > 0) {
        menuItem.push(_module);
      }
    }
  }

  return {
    items: [...menuItem],
  };
};

export default menuItems;
