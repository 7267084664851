import AuthWrapper from "pages/authentication/AuthWrapper";
import React from "react";
import {
  Grid,
  Stack,
  Typography,
} from "../../../node_modules/@mui/material/index";

const NotFound = () => {
  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <span style={{ fontSize: "50px", fontWeight: "bolder" }}>
            404 Not Found
          </span>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default NotFound;
